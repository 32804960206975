
import { defineComponent } from 'vue'
import AdminLayout from '@/templates/AdminLayout.vue'
import GetMHTSection from '@/components/sections/admin/GetMHTSection.vue'

export default defineComponent({
  name: 'AdminGetMHT',
  components: {
    AdminLayout,
    GetMHTSection
  },
  mounted() {
  }
})
