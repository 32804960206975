
import { defineComponent } from "vue";
import MainButton from "@/components/atoms/buttons/MainButton.vue";

export default defineComponent({
  name: "GetMHTSection",
  data() {
    return {};
  },
  components: {
    MainButton
  },
  mounted() {},
  methods: {
    createInviteMHT() {
      this.$router.push('/admin/create-invite-mht')
    }
  },
});
