import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "admin-get-mht" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GetMHTSection = _resolveComponent("GetMHTSection")!
  const _component_AdminLayout = _resolveComponent("AdminLayout")!

  return (_openBlock(), _createBlock(_component_AdminLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_GetMHTSection)
      ])
    ]),
    _: 1
  }))
}